import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './MyRoutes';
import VideoBackground from './components/VideoBackground';

const App = () => {
  return (
    <Router>
      <VideoBackground />
      <MyRoutes />
    </Router>
  );
}

export default App;