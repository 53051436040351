import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet} from 'react-helmet-async';
import Header from './components/Header';
import ConnectFourPage from './components/ConnectFourPage';
import Footer from './components/Footer';
import CircuitBoard from './components/CircuitBoard';

function ConnectFour() {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div className="App">
      <Helmet>
        <title>Adam Scott | Connect Four</title>
      </Helmet>

      <Header currentPage={currentPage} />
      <CircuitBoard/>
      <ConnectFourPage/>
      <Footer />
    </div>

  );
}

export default ConnectFour;