import React from 'react';

function Footer() {
  const staticDate = 'January 2025';

  return (
    <footer style={{
      backgroundColor: '#212529',
      color: '#f8f9fa',
      padding: '10px',
      fontSize: '0.8em',
      textAlign: 'center',
      position: 'fixed',
      bottom: '0',
      width: '100%',
    }}>
      &copy; {staticDate}
    </footer>
  );
}

export default Footer;