import React from 'react';
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';

function Header({ currentPage }) {
  let pageTitle = "";
  switch (currentPage) {
    case "/":
      pageTitle = "Home";
      break;
    // case "/website":
    //   pageTitle = "Website";
    //   break;
    // case "/class-project":
    //   pageTitle = "PackScheduler";
    //   break;
    case "/coming-soon":
      pageTitle = "Coming Soon!";
      break;
    // case "/crud-app":
    //   pageTitle = "Web App";
    //   break;
    // case "/editor":
    //   pageTitle = "Code Editor with Suggestions";
    //   break;
    // case "/connectfour":
    //   pageTitle = "Connect Four Deep Q Network AI";
    //   break;
    case "/contact":
      pageTitle = "Contact";
      break;
    default:
      pageTitle = "Unknown";
  }
  
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <h3 style={{color: "White"}}>{pageTitle}</h3>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link bg-dark text-light" to="/">Home</Link>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="nav-link dropdown-toggle bg-dark text-light border-0">
                Projects
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item as={Link} to="/website">Personal Website</Dropdown.Item>
                <Dropdown.Item as={Link} to="/editor">Code Editor with Suggestions</Dropdown.Item>
                <Dropdown.Item as={Link} to="/crud-app">Web App for Selling Puppies</Dropdown.Item>
                <Dropdown.Item as={Link} to="/connectfour">Connect Four Deep Q Network AI</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/coming-soon">Coming Soon!</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <li className="nav-item">
              <Link className="nav-link bg-dark text-light" to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;