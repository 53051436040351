import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function ComingSoonDetails() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={8} lg={8} md={8} sm={12}>
          <Container
            fluid
            style={{ backgroundColor: "black", padding: 0, marginTop: "10%" }}
          >
            <div
              style={{
                backgroundColor: "rgb(156, 39, 176)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "max-content",
              }}
            >
              <h1
                style={{
                  color: "white",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                What's Next?
              </h1>
            </div>
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                paddingTop: "5%",
                paddingBottom: "15%",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
              >
                I may update this with projects I have done eventually...
                <br/>

              </p>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ComingSoonDetails;
