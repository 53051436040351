import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

//cool color: "rgb(196, 46, 46)"

function HomePage() {
  return (
    <Container>
        <Row className="justify-content-center">
            <Col xl={8} lg={8} md={8} sm={12}>
                <Container fluid style={{ backgroundColor: "black", padding: 0, marginTop: "10%"}}>
                <div style={{ backgroundColor: "rgb(156, 39, 176)", display: "flex", justifyContent: "space-between", alignItems: "center", height: "max-content" }}>
                    <h1 style={{ color: "white", paddingLeft: "1%", paddingRight: "1%"}}>About me: Adam Scott</h1>
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        poster={process.env.PUBLIC_URL + "outdoor.jpg"}
                        style={{ maxWidth: "40%", maxHeight: "40%" }}
                    >
                        <source
                        src={process.env.PUBLIC_URL + "head_spinning.mp4"}
                        type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    {/* <img src={process.env.PUBLIC_URL + "outdoor.jpg"} alt="Proof that I go outside" style={{ maxWidth: "40%", maxHeight: "40%" }} /> */}
                </div>
                <div style={{ backgroundColor: "black", color: "white", paddingTop: "5%", paddingBottom: "15%", marginLeft: "2%", marginRight: "2%"}}>
                    <p style={{ textAlign: "left"}}>
                    Hey, my name is Adam Scott. I'm a master's student in computer science at NCSU. I've worked on a bunch of projects I could list here, but I think the coolest thing I've done so far is coauthor two research papers that you can check out below.
                    </p>
                    <br/>
                    <br/>

                    <a href="https://ieeexplore.ieee.org/document/10804561">
                    https://ieeexplore.ieee.org/document/10804561
                    </a>

                    <br/>
                    <br/>

                    <a href="https://dl.acm.org/doi/10.1145/3650212.3680320">
                    https://dl.acm.org/doi/10.1145/3650212.3680320
                    </a>
                </div>
                </Container>
            </Col>
        </Row>
    </Container>
  );
}

export default HomePage;