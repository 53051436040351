import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet} from 'react-helmet-async';
import Header from './components/Header';
import ComingSoonDetails from './components/ComingSoonDetails';
import Footer from './components/Footer';
import CircuitBoard from './components/CircuitBoard';
import VideoBackground from './components/VideoBackground';

function ComingSoon() {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div className="App">
      <Helmet>
        <title>Adam Scott | Coming Soon</title>
      </Helmet>

      <Header currentPage={currentPage} />
      <ComingSoonDetails />
      <Footer />
    </div>

  );
}

export default ComingSoon;