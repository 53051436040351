import React, { useState } from 'react';
import CircuitBoard from './CircuitBoard';

const VideoBackground = () => {
    const [videoError, setVideoError] = useState(false);
  
    return (
      <div className="video-background">
        {!videoError ? (
          <video
            autoPlay
            loop
            muted
            playsInline
            onError={() => setVideoError(true)} // Set fallback if video errors out
          >
            <source src={`${process.env.PUBLIC_URL}/video_background_slow.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <CircuitBoard /> 
        )}
  
        <style jsx>{`
          .video-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;
          }
  
          .video-background video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `}</style>
      </div>
    );
  };
  
  export default VideoBackground;
