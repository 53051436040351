import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function ContactPage() {
  return (
<Container>
  <Row className="justify-content-center">
    <Col xl={8} lg={8} md={8} sm={12}>
      <Container
        fluid
        style={{ backgroundColor: "black", padding: 0, marginTop: "10%" }}
      >
        <div
          style={{
            backgroundColor: "rgb(156, 39, 176)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "max-content",
          }}
        >
          <h1
            style={{
              color: "white",
              paddingLeft: "1%",
              paddingRight: "1%",
            }}
          >
            Contact me:
          </h1>
        </div>
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            paddingTop: "5%",
            paddingBottom: "15%",
          }}
        >
          <Row style={{padding: "2%"}}>
            <Col xs={12} md={3}>
              <h3 style={{textDecoration: "underline"}}>Email</h3>
            </Col>
            <Col xs={12} md={9}>
              <div style={{ marginBottom: "2%" }}>
                <a
                  href="mailto:amscott9@ncsu.edu"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#fff", textDecoration: "none" }}
                  onMouseOver={e => e.target.style.color = "#ccc"}
                  onMouseOut={e => e.target.style.color = "#fff"}
                >
                  - amscott9@ncsu.edu
                </a>
              </div>
              <div style={{ marginBottom: "1%" }}>
                <a
                  href="mailto:adamscott200322@gmail.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#fff", textDecoration: "none" }}
                  onMouseOver={e => e.target.style.color = "#ccc"}
                  onMouseOut={e => e.target.style.color = "#fff"}
                >
                  - adamscott200322@gmail.com
                </a>
              </div>
            </Col>
          </Row>
          <Row style={{padding: "2%",}}>
            <Col xs={12} md={3}>
              <h3 style={{textDecoration: "underline"}}>LinkedIn</h3>
            </Col>
            <Col xs={12} md={9}>
              <a
                href="https://www.linkedin.com/in/adam-scott-911469230/"
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: "#fff", textDecoration: "none" }}
                onMouseOver={e => e.target.style.color = "#ccc"}
                onMouseOut={e => e.target.style.color = "#fff"}
              >
                - amscott9
              </a>
            </Col>
          </Row>
          <Row style={{padding: "2%"}}>
            <Col xs={12} md={3}>
              <h3 style={{textDecoration: "underline"}}>Github</h3>
            </Col>
            <Col xs={12} md={9}>
              <div style={{ marginBottom: "1%" }}>
                <a
                  href="https://github.com/as4230"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "#fff", textDecoration: "none" }}
                  onMouseOver={e => e.target.style.color = "#ccc"}
                  onMouseOut={e => e.target.style.color = "#fff"}
                >
                  - as4230
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Col>
  </Row>
</Container>

  );
}

export default ContactPage;
