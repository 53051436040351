import './components/Home.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import ContactPage from './components/ContactPage';
import { Helmet } from 'react-helmet-async';
import CircuitBoard from './components/CircuitBoard';
import Footer from './components/Footer';
import VideoBackground from './components/VideoBackground';

function Contact() {
  const location = useLocation();
  const currentPage = location.pathname;
  
  return (
    <div className="App">
      <Helmet>
        <title>Adam Scott | Contact</title>
      </Helmet>
      <Header currentPage={currentPage} />
      <ContactPage />
      <Footer />

    </div>
  );
}

export default Contact;